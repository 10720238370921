import React, { useLayoutEffect, useRef } from "react";
import partner from "../assets/img/collab.gif";
import "../styles/partner.scss";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const Partner: React.FC = () => {
  const textInnerRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      const headingInner = textInnerRef.current;

      if (headingInner) {
        const tl = gsap.timeline({
          scrollTrigger: {
            trigger: textInnerRef.current,
            start: "top bottom",
            end: "top 30%",
            scrub: true,
          },
        });

        tl.from(textInnerRef.current, { y: 200 });
      }
    });

    return () => ctx.revert();
  }, []);

  return (
    <div className="partnermaindiv">
      <img src={partner} alt="partner" />
      <div>
        <div ref={textInnerRef}>
          <h1 className="heading">Your true partner</h1>
          <p className="description">
            Launching a new technology product? or Optimising your existing
            solution? You can count on us.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Partner;
