import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import "../styles/heading.scss";
import foreground from "../assets/img/foreground.png";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const Heading: React.FC = () => {
  const [twinklingIndex, setTwinklingIndex] = useState<number>(0);
  const headingInnerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const interval = setInterval(() => {
      setTwinklingIndex((prevIndex) => (prevIndex + 1) % 200);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      const headingInner = headingInnerRef.current;

      if (headingInner) {
        const tl = gsap.timeline({
          scrollTrigger: {
            trigger: "body",
            endTrigger: headingInnerRef.current,
            start: "top top",
            end: "bottom top",
            scrub: true,
          },
        });

        const headingContent = headingInner.querySelector(".headingContent");
        if (headingContent) {
          tl.to(headingContent, { y: -300 });
        }
      }
    });

    return () => ctx.revert();
  }, []);

  return (
    <div className="headingMain">
      <div className="headingInner" ref={headingInnerRef}>
        <div className="headingImg">
          <img src={foreground} alt="foreground" />
        </div>
        <div className="barDiv"></div>
        <div className="headingContent">
          <h1>Beyond Seek</h1>
          <h2>RIGOROUS, AGILE, RELIABLE, EVOLVING.</h2>
        </div>
      </div>
      {[...Array(200)].map((_, i) => (
        <div key={i} className={`twinkling-star twinkling-star-${i}`} />
      ))}
    </div>
  );
};

export default Heading;
