import React, { useEffect, useLayoutEffect, useRef } from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import Service from "./Service";
import discussion from "../assets/img/discussion.json";
import design from "../assets/img/design.json";
import code from "../assets/img/code_new.json";
import secure from "../assets/img/secure.json";
gsap.registerPlugin(ScrollTrigger);

const Services: React.FC = () => {
  const getTriggerConfig = (trigger: string) => ({
    duration: 1,
    scrollTrigger: {
      trigger: trigger,
      start: "top bottom",
      end: "top 30%",
      scrub: 1,
    },
  });

  const diff = 70;

  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      if (window.innerWidth > 767) {
        gsap.from(".reverseflex_1 .imagecontainer", {
          x: -diff,
          ...getTriggerConfig(".reverseflex_1"),
        });

        gsap.from(".reverseflex_1 .details", {
          x: diff,
          ...getTriggerConfig(".reverseflex_1"),
        });

        gsap.from(".reverseflex_2 .imagecontainer", {
          x: -diff,
          ...getTriggerConfig(".reverseflex_2"),
        });

        gsap.from(".reverseflex_2 .details", {
          x: diff,
          ...getTriggerConfig(".reverseflex_2"),
        });

        gsap.from(".normalflex_1 .imagecontainer", {
          x: diff,
          ...getTriggerConfig(".normalflex_1"),
        });

        gsap.from(".normalflex_1 .details", {
          x: -diff,
          ...getTriggerConfig(".normalflex_1"),
        });

        gsap.from(".normalflex_2 .imagecontainer", {
          x: diff,
          ...getTriggerConfig(".normalflex_2"),
        });

        gsap.from(".normalflex_2 .details", {
          x: -diff,
          ...getTriggerConfig(".normalflex_2"),
        });
      }
    });

    return () => ctx.revert();
  }, []);

  return (
    <div>
      <div className="service-container" data-style="reverseflex_1">
        <Service
          styleName="reverseflex_1"
          img={discussion}
          heading="DEEP DISCUSSIONS"
          discussions="We love taking a deep dive with you, we make sure to spend as much time with you possible before we can start moving the gears."
        />
      </div>
      <div className="service-container" data-style="normalflex_1">
        <Service
          styleName="normalflex_1"
          img={design}
          heading="Prototypes, Designs"
          discussions="Preview your vision before it's real, in the fastest way possible. Get early user feedback using a painted red door."
        />
      </div>
      <div className="service-container" data-style="reverseflex_2">
        <Service
          styleName="reverseflex_2"
          img={code}
          heading="Cross-platform development"
          discussions="On a tight budget? We hear you. We pick the right tools for the job. We will make sure that the solution is deployed to as many platforms possible."
        />
      </div>
      <div className="service-container" data-style="normalflex_2">
        <Service
          styleName="normalflex_2"
          img={secure}
          heading="Scalable, Secure."
          discussions="This goes without saying but we use the best standards of security and start with a scalable architecture in mind. We got your back."
        />
      </div>
    </div>
  );
};

export default Services;
