import React from "react";
import "../styles/footer.scss";
import logo from "../assets/img/foreground.png";
import Social from "./Social";

const Footer: React.FC = () => {
  return (
    <div className="mainFooterContainer">
      <div className="footerMain">
        <div className="footerImgDiv">
          <img src={logo} alt="" />
        </div>
        <div className="location">
          <div>
            <h3>Reach us at</h3>
            <a href="mailto:hello@beyondseek.com">
              <p>hello@beyondseek[.]com</p>
            </a>
          </div>
          <h4>India</h4>
          <p>Beyond Seek Technologies Pvt. Ltd.</p>
          <p>
            67, 2nd cross, VHBCS Layout,
            <br /> West of Chord Road 2nd Stage, Bengaluru 560086
          </p>
        </div>
        <div className="location">
          <div className="hidden">
            <h3>Reach us at</h3>
            <a href="mailto:hello@beyondseek.com">
              <p>hello@beyondseek[.]com</p>
            </a>
          </div>
          <h4>Canada</h4>
          <p>Beyond Seek Technologies Inc.</p>
          <p>3500-800 RUE du Square-Victoria, Montréal, Québec H4Z1E9</p>
        </div>
      </div>
      <Social />
    </div>
  );
};

export default Footer;
