import React from "react";
import { GrFacebookOption } from "react-icons/gr";
import { FaLinkedinIn } from "react-icons/fa6";
import "../styles/social.scss";

const Social: React.FC = () => {
  const currentYear = new Date().getFullYear();
  return (
    <div className="socialMain">
      <div className="iconDiv">
        <a
          className="fbicon"
          href="https://www.facebook.com/gobeyondseek"
          target="_blank"
        >
          <GrFacebookOption color="black" />
        </a>
        <a
          className="fbicon"
          href="https://www.linkedin.com/company/beyondseek/"
          target="_blank"
        >
          <FaLinkedinIn color="black" />
        </a>
      </div>
      <p>© {currentYear} Beyond Seek Technologies</p>
    </div>
  );
};

export default Social;
