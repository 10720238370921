import React, { useEffect, useLayoutEffect, useRef } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "../styles/about.scss";
gsap.registerPlugin(ScrollTrigger);

const About: React.FC = () => {
  const bgDivRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      if (bgDivRef.current) {
        gsap.to(bgDivRef.current, {
          rotation: 360,
          scrollTrigger: {
            trigger: bgDivRef.current,
            start: "top bottom",
            end: "bottom center",
            scrub: true,
            endTrigger: "body",
          },
        });
      }
    });

    return () => ctx.revert();
  }, []);

  return (
    <div className="maindiv">
      <h2 className="aboutheading">To Code or to NoCode?</h2>
      <p className="description">
        We help you decide and implement the best solution in the most efficient
        way possible today.
        <br />
        <br />
        Analyse, design and develop your software with us.
      </p>
      <div className="bgDiv" ref={bgDivRef}></div>
    </div>
  );
};

export default About;
