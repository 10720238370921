import React from "react";
import "../App.scss";
import About from "./About";
import Partner from "./Partner";
import Services from "./Services";
import Contact from "./Contact";
import Heading from "./Heading";
import Footer from "./Footer";

const Main: React.FC = () => {
  return (
    <div className="mainContainer">
      <Heading />
      <About />
      <Partner />
      <Services />
      <Contact />
      <Footer />
    </div>
  );
};

export default Main;
