import Lottie from "lottie-react";
import React, { useEffect, useRef } from "react";
import "../styles/service.scss";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

interface servicesProps {
  heading: string;
  discussions: string;
  img: string | any;
  styleName: string;
}

const Service: React.FC<servicesProps> = ({
  img,
  heading,
  discussions,
  styleName,
}) => {
  return (
    <div className={styleName}>
      <div className="imagecontainer">
        <Lottie className="img" animationData={img} loop autoplay />
      </div>
      <div className="details">
        <h2>{heading}</h2>
        <p>{discussions}</p>
      </div>
    </div>
  );
};

export default Service;
